import React, { useContext, useEffect, useState } from "react";
import Table from "components/Table";
import { TABLE_COLUMN } from "../config/enum";
import { ContextPromo } from "../context/ContextProvider";
import { getPromo, updatePromo } from "apis/promo";
import { removeEmptyValues } from "helper/index";
import { useToast } from "@chakra-ui/react";
import ModalContainer from "components/Modal";

const List = () => {
  const { query, setTable, table } = useContext(ContextPromo);
  const toast = useToast();
  const [promoList, setPromoList] = useState([]);

  const fetchPromo = async (params = {}) => {
    try {
      const res = await getPromo(removeEmptyValues(params));
      setPromoList(res.data);
      setTable((prev) => ({
        ...prev,
        pagination: {
          total: res.last_page,
          per_page: res.per_page,
          current_page: res.current_page,
        },
      }));
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data promo",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    fetchPromo({
      page: 1,
      limit: 10,
      ...query.watch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.watch]);

  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState("");

  return (
    <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
      <Table
        columnsData={TABLE_COLUMN((id) => {
          setOpenEdit(true);
          setEditId(id);
        })}
        tableData={promoList}
        paginationCallback={(page, offset) =>
          fetchPromo({ page, limit: offset })
        }
        currentPage={table.pagination.current_page}
        perPage={table.pagination.per_page}
        pageCount={table.pagination.total}
      />
      <ModalContainer
        onClose={() => setOpenEdit(false)}
        isOpen={openEdit}
        onSubmit={async () => {
          try {
            await updatePromo({ id: editId });

            setOpenEdit(false);

            fetchPromo({
              page: 1,
              limit: 10,
              ...query.watch,
            });

            toast({
              title: "Sukses",
              description: "berhasil menonaktifkan promo",
              status: "success",
              position: "top",
            });
          } catch (err) {
            toast({
              title: "Gagal",
              description: "gagal menonaktifkan promo",
              status: "error",
              position: "top",
            });
          }
        }}
        modalTitle={`Nonaktifkan Promo`}
      >
        Apakah anda yakin ingin menonaktifkan promo ini?
      </ModalContainer>
    </div>
  );
};

export default List;
