import { useContext } from "react";
import { ContextHistoryStock } from "../context/ContextProvider";
import Text from "components/Text";
import Table from "components/Table";
import { TABLE_COLUMN_DETAIL } from "../config/enum";
import ModalContainer from "components/Modal";
import moment from "moment";

const ModalDetail = () => {
  const { open, setOpen, setOpenSN, detailData } =
    useContext(ContextHistoryStock);
  return (
    <ModalContainer
      isOpen={open}
      modalTitle={`Detail Stok Sales`}
      onClose={() => {
        setOpen(false);
      }}
      hideButton
    >
      <Text variant="h3" content="Informasi Dasar" />
      <div className="mb-6 mt-6">
        <div className="flex gap-4">
          <div className="flex flex-grow flex-col gap-2">
            <Text variant="linkBold" content="Sales" />
            <Text variant="p" content={detailData?.sales_name} />
          </div>
          <div className="flex flex-grow flex-col gap-2">
            <Text variant="linkBold" content="Wilayah Penjualan" />
            <Text variant="p" content={detailData?.region?.region_name} />
          </div>
          <div className="flex flex-grow flex-col gap-2">
            <Text variant="linkBold" content="Last Update" />
            <Text
              variant="p"
              content={moment(detailData?.updated_at).format("DD MMM YYYY")}
            />
          </div>
          <div className="flex flex-grow flex-col gap-2">
            <Text variant="linkBold" content="Catatan" />
            <Text variant="p" content="-" />
          </div>
        </div>
      </div>
      <div className="my-3 border-b-[1px] border-[#E4E5E8]"></div>
      <Table
        columnsData={TABLE_COLUMN_DETAIL({ setOpenSN })}
        tableData={detailData?.unclosed_kas?.kas_details || []}
        hidePagination
      />
    </ModalContainer>
  );
};

export default ModalDetail;
