import InputSearch from "components/Form/InputSearch";
import InputSelect from "components/Form/InputSelect";
import { optionTransaction } from "../config/options";
import { debounce } from "helper/index";
import { useContext } from "react";
import { ContextTransaction } from "../context/ContextProvider";

const FilterTable = () => {
  const { setQuery } = useContext(ContextTransaction);

  return (
    <div className="relative flex items-end gap-3">
      <div className="flex-grow">
        <InputSearch
          changeHandler={debounce(
            (e) =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, keyword: e.target.value, page: 1 },
              })),
            500
          )}
          rounded
        />
      </div>
      <div className="w-1/4">
        <InputSelect
          label="Pilih jenis transaksi"
          onChange={(e) =>
            setQuery((prevQuery) => ({
              ...prevQuery,
              watch: { ...prevQuery.watch, is_paid: e.target.value, page: 1 },
            }))
          }
          optionList={optionTransaction}
          rounded
        />
      </div>
    </div>
  );
};

export default FilterTable;
