import Table from "components/Table";
import Card from "components/card";
import { TableColumn } from "./enum";
import Button from "components/Button";
import { MdAdd, MdDelete, MdOutlineFileUpload } from "react-icons/md";
import InputSelect from "components/Form/InputSelect";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSearch from "components/Form/InputSearch";
import Modal from "components/Modal";
import { useToast } from "@chakra-ui/react";
import SwitchField from "components/fields/SwitchField";
import InputField from "components/fields/InputField";
import { useFieldArray, useForm } from "react-hook-form";
import {
  createProduct,
  deleteProduct,
  getProduct,
  updateProduct,
} from "apis/product";
import { debounce } from "helper/index";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import Text from "components/Text";
import InputUpload from "components/Form/InputUpload";
import { getProductCategory } from "apis/product_category";
import { getRegion } from "apis/region";
import { getErrorField } from "helper/geterrorfield";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./utils";
import { getUser } from "helper/localstorage";
import { getGudang } from "apis/gudang";

const Produk = () => {
  const initialFormData = {
    product_name: "",
    sku: "",
    capital_price: 0,
    selling_price: 0,
    selling_price_grosir: 0,
    description: "",
    id_category: null,
    status: 0,
    image_url: [],
    product_id: null,
    id_gudang: [{ id_gudang: "", price_gudang: 0, price_gudang_grosir: 0 }],
  };
  const [deleteModal, setDeleteModal] = useState(false);

  const { setHeaderComponent } = useContext(LayoutContext);
  const forms = useForm({
    defaultValues: initialFormData,
    resolver: yupResolver(validations),
  });
  const fieldArray = useFieldArray({
    name: "id_gudang",
    control: forms.control,
    keyName: "id",
  });

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [table, setTable] = useState({
    loading: false,
    data: [],
    pagination: {
      total_data: 1,
      total_page: 1,
      current_page: 1,
      per_page: 10,
      status: "",
      keyword: "",
      id_category: "",
    },
  });
  const [options, setOptions] = useState({ category: [], area: [] });
  const isAdmin = getUser().id_role === 1;

  const fetchTable = async (params = {}) => {
    const response = await getProduct(params);

    setTable((prev) => ({
      ...prev,
      data: response.data,
      pagination: {
        total_page: response.last_page,
        total_data: response.total,
        per_page: response.per_page,
        current_page: response.current_page,
        status: params.status,
        keyword: params.keyword,
        id_category: params.id_category,
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchTable();
      } catch (err) {
        toast({
          title: "Gagal",
          description: err,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [area, category] = await Promise.all([
          getRegion(),
          getProductCategory(),
        ]);
        setOptions({ area: area.data, category: category.data });
      } catch (err) {
        toast({
          title: "Gagal",
          description: err,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SELECT_DATA = [
    {
      label: "Semua Status",
      value: "",
    },
    {
      label: "Tampil",
      value: 1,
    },
    {
      label: "Tidak Tampil",
      value: 0,
    },
  ];

  const toast = useToast();

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      const payload = {
        ...values,
        id_gudang: values.id_gudang.map((t) => ({
          id_gudang: t.id_gudang,
          price_gudang: t.price_gudang,
          price_gudang_grosir: t.price_gudang_grosir,
        })),
      };

      if (values.id_product) {
        delete values.product_id;

        await updateProduct(payload);
      } else {
        await createProduct(payload);
      }

      setIsOpenForm(false);

      forms.reset(initialFormData);

      fetchTable({ page: table.pagination.current_page, limit: 10 });

      toast({
        title: "Berhasil",
        description: `Sukses ${values.id ? "mengedit" : "menambahkan"} produk`,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      toast({
        title: "Gagal",
        description: err,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  });

  const onTableChange = (tableState) => {
    console.log(tableState);
  };

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        {/* <Button
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        /> */}
        {isAdmin && (
          <Button
            label="Tambah Produk"
            variant="DEFAULT"
            size="sm"
            leftIcon={<MdAdd />}
            onClickHandler={() => {
              setIsOpenForm(!isOpenForm);
            }}
          />
        )}
      </div>
    );
  }, [setIsOpenForm, isOpenForm, isAdmin]);

  return (
    <Card
      extra={
        "w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col"
      }
    >
      <div className="relative flex items-end gap-3">
        <div className="w-1/3">
          <InputSearch
            rounded
            changeHandler={debounce(
              (e) =>
                fetchTable({
                  keyword: e.target.value,
                  page: 1,
                  limit: 10,
                  status: table.pagination.status,
                  id_category: table.pagination.id_category,
                }),
              500
            )}
          />
        </div>
        <div className="w-1/3">
          <InputMultipleSelect
            fetchList={getProductCategory}
            keyLabel="category_name"
            keyValue="id_category"
            label="Pilih kategori"
            onChange={(value) => {
              fetchTable({
                keyword: table.pagination.keyword,
                id_category: value,
                page: 1,
                limit: 10,
                status: table.pagination.status,
              });

              setTable((prev) => ({
                ...prev,
                pagination: { ...prev.pagination, id_category: value },
              }));
            }}
            value={table.pagination.id_category}
            rounded
          />
        </div>
        <div className="w-1/3">
          <InputSelect
            optionList={SELECT_DATA}
            rounded
            defaultValue=""
            label="Pilih status"
            onChange={(e) =>
              fetchTable({
                page: 1,
                limit: 10,
                status: e.target.value,
                keyword: table.pagination.keyword,
                id_category: table.pagination.id_category,
              })
            }
          />
        </div>
      </div>

      <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
        <Table
          columnsData={TableColumn(
            (data) => {
              forms.setValue("product_name", data.product_name);
              forms.setValue("id_category", data.id_category);
              forms.setValue("description", data.description);
              forms.setValue("status", data.status);
              forms.setValue("capital_price", data.capital_price);
              forms.setValue("selling_price", data.selling_price);
              forms.setValue(
                "selling_price_grosir",
                data?.selling_price_grosir
              );
              forms.setValue("sku", data.sku);
              forms.setValue("id_product", data.id_product);
              forms.setValue("category_name", data.category.category_name);
              forms.setValue(
                "id_gudang",
                data.assigned_gudang.map((g) => ({
                  id_gudang: g.id_gudang,
                  gudang_name: g.gudang.gudang_name,
                  price_gudang: g.price_gudang,
                  price_gudang_grosir: g.price_gudang_grosir,
                }))
              );
              forms.setValue(
                "image_url",
                data.product_assets.map((prd) => prd.path)
              );
              setIsOpenForm(true);
            },
            (data) => {
              forms.setValue("id_product", data.id_product);
              setDeleteModal(true);
            }
          )}
          tableData={table.data}
          paginationCallback={(page, offset) =>
            fetchTable({ page, limit: offset })
          }
          totalData={table.pagination.total_data}
          currentPage={table.pagination.current_page}
          perPage={table.pagination.per_page}
          pageCount={table.pagination.total_page}
          onTableChange={onTableChange}
        />
      </div>
      <Modal
        isOpen={isOpenForm}
        modalTitle={`${forms.watch("id_product") ? "Edit" : "Tambah"} Produk`}
        onClose={() => {
          forms.reset(initialFormData);
          setIsOpenForm(!isOpenForm);
        }}
        isSubmitting={forms.formState.isSubmitting}
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-4 sm:max-h-96 sm:w-[800px]">
          <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
            <div className="sm:w-1/2">
              <InputField
                label="Nama Produk"
                register={forms.register("product_name")}
                required
                state={
                  getErrorField(forms.formState.errors, "product_name").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "product_name")}
              />
            </div>
            <div className="sm:w-1/2">
              <InputMultipleSelect
                required
                label="Kategori Produk"
                fetchList={getProductCategory}
                keyLabel="category_name"
                keyValue="id_category"
                initialLabel={forms.watch("category_name")}
                state={
                  getErrorField(forms.formState.errors, "id_category").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "id_category")}
                onChange={(value) => {
                  forms.setValue("id_category", value, {
                    shouldValidate: true,
                  });
                }}
                value={forms.watch("id_category")}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4 sm:flex-row">
            <div className="sm:w-1/2">
              <InputField
                required
                state={
                  getErrorField(forms.formState.errors, "sku").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "sku")}
                label="SKU"
                register={forms.register("sku")}
              />
            </div>
          </div>
          {fieldArray.fields.map((field, idx) => (
            <div className="flex flex-col gap-2" key={field.id}>
              <div className="grid grid-cols-3 gap-2">
                <InputMultipleSelect
                  fetchList={getGudang}
                  keyLabel="gudang_name"
                  label="Gudang"
                  required
                  noLimit
                  initialLabel={
                    forms.watch("id_product")
                      ? forms.watch(`id_gudang.${idx}.gudang_name`)
                      : "Pilih gudang"
                  }
                  keyValue="id_gudang"
                  onChange={(value) => {
                    forms.setValue(`id_gudang.${idx}.id_gudang`, value, {
                      shouldValidate: true,
                    });
                  }}
                  errors={getErrorField(
                    forms.formState.errors,
                    `id_gudang.${idx}.id_gudang`
                  )}
                  value={forms.watch(`id_gudang.${idx}.id_gudang`, [])}
                />
                <InputField
                  label="Harga Retail"
                  register={forms.register(`id_gudang.${idx}.price_gudang`)}
                  required
                  state={
                    getErrorField(
                      forms.formState.errors,
                      `id_gudang.${idx}.price_gudang`
                    ).show
                      ? "error"
                      : ""
                  }
                  errors={getErrorField(
                    forms.formState.errors,
                    `id_gudang.${idx}.price_gudang`
                  )}
                />
                <InputField
                  label="Harga Gudang Grosir"
                  register={forms.register(
                    `id_gudang.${idx}.price_gudang_grosir`
                  )}
                  required
                  state={
                    getErrorField(
                      forms.formState.errors,
                      `id_gudang.${idx}.price_gudang_grosir`
                    ).show
                      ? "error"
                      : ""
                  }
                  errors={getErrorField(
                    forms.formState.errors,
                    `id_gudang.${idx}.price_gudang_grosir`
                  )}
                />
              </div>
              <div className="flex justify-end">
                {fieldArray.fields.length > 1 && (
                  <button
                    className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#FECACA] text-[#E31B1A]"
                    onClick={() => fieldArray.remove(idx)}
                  >
                    <MdDelete />
                  </button>
                )}
              </div>
            </div>
          ))}
          <div className="flex justify-end">
            <Button
              variant="DEFAULT"
              customStyle="w-fit"
              label="Tambah"
              onClickHandler={() =>
                fieldArray.append({
                  id_gudang: "",
                  price_gudang: 0,
                  price_gudang_grosir: 0,
                })
              }
            />
          </div>
          <InputField
            label="Deskripsi Produk"
            register={forms.register("description")}
            required
            state={
              getErrorField(forms.formState.errors, "description").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "description")}
          />
          <SwitchField
            label="Visibilitas Produk"
            desc="Tampilkan Produk di Menu"
            value={forms.watch("status") === 1}
            onChange={(e) => forms.setValue("status", e.target.checked ? 1 : 0)}
          />
          <InputField
            type="number"
            label="Harga Beli"
            required
            state={
              getErrorField(forms.formState.errors, "capital_price").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "capital_price")}
            register={forms.register("capital_price")}
          />
          <InputField
            type="number"
            label="Harga Jual Retail"
            required
            state={
              getErrorField(forms.formState.errors, "selling_price").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "selling_price")}
            register={forms.register("selling_price")}
          />
          <InputField
            type="number"
            label="Harga Jual Grosir"
            required
            state={
              getErrorField(forms.formState.errors, "selling_price_grosir").show
                ? "error"
                : ""
            }
            errors={getErrorField(
              forms.formState.errors,
              "selling_price_grosir"
            )}
            register={forms.register("selling_price_grosir")}
          />
          <div className="flex flex-col gap-3">
            <Text variant="linkBold" content="Foto Produk (Maksimal 5)" />
            <div className="flex gap-3">
              {[0, 1, 2, 3, 4].map((v) => (
                <div key={v} className="aspect-square w-1/6">
                  <InputUpload
                    accept="image/*"
                    setValue={(data) =>
                      forms.setValue(`image_url.${v}`, data[0].url)
                    }
                    image={forms.watch(`image_url.${v}`, "")}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal}
        modalTitle="Hapus Produk"
        onClose={() => {
          forms.setValue("id_product", "");
          setDeleteModal(false);
        }}
        isSubmitting={forms.formState.isSubmitting}
        onSubmit={async (values) => {
          await deleteProduct({ id: forms.watch("id_product") });

          await fetchTable({ page: table.pagination.current_page, limit: 10 });

          toast({
            title: "Berhasil",
            description: `Sukses menghapus produk`,
            status: "success",
            isClosable: true,
            position: "top-right",
          });

          forms.setValue("id_product", "");

          setDeleteModal(false);
        }}
      >
        Apakah anda yakin ingin menghapus produk ini?
      </Modal>
    </Card>
  );
};

export default Produk;
