import Table from "components/Table";
import Card from "components/card";
import { TableColumn } from "./enum";
import Button from "components/Button";
import { MdAdd, MdOutlineFileUpload } from "react-icons/md";
import InputSelect from "components/Form/InputSelect";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSearch from "components/Form/InputSearch";
import Modal from "components/Modal";
import { useToast } from "@chakra-ui/react";
import SwitchField from "components/fields/SwitchField";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import {
  createProductCategory,
  deleteProductCategory,
  getProductCategory,
  updateProductCategory,
} from "apis/product_category";
import { debounce } from "helper/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./utils";
import { getErrorField } from "helper/geterrorfield";

const Kategori = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const forms = useForm({
    defaultValues: { category_name: "", status: 0, id: null },
    resolver: yupResolver(validations),
  });
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [table, setTable] = useState({
    loading: false,
    data: [],
    pagination: {
      total_data: 1,
      total_page: 1,
      current_page: 1,
      per_page: 10,
      status: "",
      keyword: "",
    },
  });

  const fetchTable = async (params = {}) => {
    const response = await getProductCategory(params);

    setTable((prev) => ({
      ...prev,
      data: response.data,
      pagination: {
        total_page: response.last_page,
        total_data: response.total,
        per_page: response.per_page,
        current_page: response.current_page,
        status: params.status,
        keyword: params.keyword,
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchTable();
      } catch (err) {
        toast({
          title: "Gagal",
          description: err,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SELECT_DATA = [
    {
      label: "Semua Status",
      value: "",
    },
    {
      label: "Tampil",
      value: 1,
    },
    {
      label: "Tidak Tampil",
      value: 0,
    },
  ];

  const toast = useToast();

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      if (values.id) {
        await updateProductCategory(values);
      } else {
        await createProductCategory(values);
      }

      setIsOpenForm(false);

      forms.reset({ category_name: "", status: 0, id: null });

      fetchTable({ page: table.pagination.current_page, limit: 10 });

      toast({
        title: "Berhasil",
        description: `Sukses ${
          values.id ? "mengedit" : "menambahkan"
        } kategori`,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      toast({
        title: "Gagal",
        description: err,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  });

  const onTableChange = (tableState) => {
    console.log(tableState);
  };

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        {/* <Button
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        /> */}
        <Button
          label="Tambah Kategori"
          variant="DEFAULT"
          size="sm"
          leftIcon={<MdAdd />}
          onClickHandler={() => {
            setIsOpenForm(!isOpenForm);
          }}
        />
      </div>
    );
  }, [setIsOpenForm, isOpenForm]);

  return (
    <Card
      extra={
        "w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col"
      }
    >
      <div className="relative flex items-end gap-3">
        <div className="flex-grow">
          <InputSearch
            rounded
            changeHandler={debounce(
              (e) =>
                fetchTable({
                  keyword: e.target.value,
                  page: 1,
                  limit: 10,
                  status: table.pagination.status,
                }),
              500
            )}
          />
        </div>
        <div className="w-1/5">
          <InputSelect
            optionList={SELECT_DATA}
            rounded
            label="Pilih status"
            defaultValue=""
            onChange={(e) =>
              fetchTable({
                page: 1,
                limit: 10,
                status: e.target.value,
                keyword: table.pagination.keyword,
              })
            }
          />
        </div>
      </div>

      <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
        <Table
          columnsData={TableColumn(
            (data) => {
              forms.setValue("category_name", data.category_name);
              forms.setValue("status", data.status);
              forms.setValue("id", data.id_category);
              setIsOpenForm(true);
            },
            (data) => {
              forms.setValue("id", data.id_category);
              setDeleteModal(true);
            }
          )}
          tableData={table.data}
          paginationCallback={(page, offset) =>
            fetchTable({ page, limit: offset })
          }
          totalData={table.pagination.total_data}
          currentPage={table.pagination.current_page}
          perPage={table.pagination.per_page}
          pageCount={table.pagination.total_page}
          onTableChange={onTableChange}
        />
      </div>
      <Modal
        isOpen={deleteModal}
        modalTitle="Hapus Kategori"
        onClose={() => {
          forms.reset({ category_name: "", status: 0, id: null });
          setDeleteModal(false);
        }}
        isSubmitting={forms.formState.isSubmitting}
        onSubmit={forms.handleSubmit(async (values) => {
          await deleteProductCategory({ id: values.id });

          await fetchTable({ page: table.pagination.current_page, limit: 10 });

          toast({
            title: "Berhasil",
            description: `Sukses menghapus kategori`,
            status: "success",
            isClosable: true,
            position: "top-right",
          });

          setDeleteModal(false);
        })}
      >
        Apakah anda yakin ingin menghapus kategori ini?
      </Modal>
      <Modal
        isOpen={isOpenForm}
        modalTitle={`${forms.watch("id") ? "Edit" : "Tambah"} Kategori`}
        onClose={() => {
          forms.reset({ category_name: "", status: 0, id: null });
          setIsOpenForm(!isOpenForm);
        }}
        isSubmitting={forms.formState.isSubmitting}
        onSubmit={handleSubmit}
      >
        <div className="flex w-[450px] flex-col gap-4">
          <InputField
            register={forms.register("category_name")}
            label="Nama Kategori"
            required
            state={
              getErrorField(forms.formState.errors, "category_name").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "category_name")}
          />
          <SwitchField
            label="Tampilkan Kategori di Menu"
            value={forms.watch("status") === 1}
            onChange={(e) => forms.setValue("status", e.target.checked ? 1 : 0)}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default Kategori;
