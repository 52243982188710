import InputSearch from "components/Form/InputSearch";
import InputSelect from "components/Form/InputSelect";
import { optionPromoType, optionStatus } from "../config/options";
import { useContext } from "react";
import { ContextPromo } from "../context/ContextProvider";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { debounce } from "helper/index";

const Filter = () => {
  const { setQuery, query } = useContext(ContextPromo);

  return (
    <div className="relative flex items-end gap-3">
      <div className="flex-grow">
        <InputSearch
          rounded
          changeHandler={debounce(
            (e) =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, keyword: e.target.value, page: 1 },
              })),
            500
          )}
        />
      </div>
      <div className="flex-1">
        <InputMultipleSelect
          optionList={optionPromoType}
          hideSearch
          label="Pilih jenis promo"
          value={query.watch.bonus_id}
          rounded
          onChange={(e) => {
            setQuery((prevQuery) => ({
              ...prevQuery,
              watch: { ...prevQuery.watch, bonus_id: e, page: 1 },
            }));
          }}
        />
      </div>
      <div className="flex-1">
        <InputSelect
          optionList={optionStatus}
          rounded
          label="Pilih status"
          value={optionStatus.find((opt) => opt.value === query.watch.status)}
          onChange={(e) =>
            setQuery((prevQuery) => ({
              ...prevQuery,
              watch: { ...prevQuery.watch, status: e.target.value, page: 1 },
            }))
          }
        />
      </div>
    </div>
  );
};

export default Filter;
