import Table from "components/Table";
import Card from "components/card";
import { TABLE_COLUMN, validations } from "./enum";
import Button from "components/Button";
import { MdAdd, MdOutlineFileUpload } from "react-icons/md";
import InputSelect from "components/Form/InputSelect";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSearch from "components/Form/InputSearch";
import ModalContainer from "components/Modal";
import InputUpload from "components/Form/InputUpload";
import Text from "components/Text";
import { getLocations } from "apis/locations";
import { useToast } from "@chakra-ui/react";
import { createSales, deleteSales, getSales, updateSales } from "apis/sales";
import { getRoles } from "apis/roles";
import { useForm } from "react-hook-form";
import InputField from "components/fields/InputField";
import { getErrorField } from "helper/geterrorfield";
import { yupResolver } from "@hookform/resolvers/yup";
import Confirmation from "components/Modal/Confirmation";
import { debounce } from "helper/index";
import { getUserSalesExport } from "apis/export";
import { getCity } from "apis/city";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getUsers } from "apis/users";

const Sales = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [formType, setFormType] = useState("create");
  const [locationList, setLocationList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [table, setTable] = useState({
    loading: false,
    pagination: { total: 1, current_page: 1, per_page: 10 },
  });
  const [query, setQuery] = useState({ id_user: null, id_region: null, keyword: "" });

  const defaultFieldValues = {
    id_sales: "",
    sales_name: "",
    id_region: "",
    id_sales_role: "",
    email: "",
    password: "",
    confirm_password: "",
    pin: "",
    confirm_pin: "",
    image_url: [],
  };

  const toast = useToast();

  const forms = useForm({
    defaultValues: defaultFieldValues,
    resolver: yupResolver(validations),
  });

  const onTableChange = (tableState) => {
    console.log(tableState);
  };

  const fetchLocations = async () => {
    try {
      const res = await getLocations({ limit: 10, page: 1 });

      const formattedData = res.map((item) => ({
        value: item.id_region,
        label: item.region_name,
      }));
      setLocationList([{ value: "", label: "Pilih Area" }, ...formattedData]);
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data lokasi",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  const fetchRoles = async () => {
    try {
      const res = await getRoles({ limit: 10, page: 1 });

      const formattedData = res.data.map((item) => ({
        value: item.id_sales_role,
        label: item.role_name,
      }));
      setRoleList([{ value: "", label: "Pilih Role" }, ...formattedData]);
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data role",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  const fetchSales = async (params = {}) => {
    try {
      const res = await getSales(params);
      setSalesList(res.data);
      setTable((prev) => ({
        ...prev,
        pagination: {
          total: res.last_page,
          per_page: res.per_page,
          current_page: res.current_page,
        },
      }));
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data sales",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      const payload = {
        ...(formType === "edit" && { id_sales: values.id_sales }),
        sales_name: values.sales_name,
        id_region: parseInt(values.id_region),
        id_city: parseInt(values.id_city),
        id_user_pic: parseInt(values.id_user_pic),
        id_sales_role: parseInt(values.id_sales_role),
        ...(values.id_sales_role === "1"
          ? { email: values.email, password: values.password }
          : { sales_pin: String(values.pin) }),
        image_url: values.image_url,
      };

      if (formType === "create") {
        await createSales(payload);
      } else {
        await updateSales(payload);
      }

      setIsOpenForm(false);

      forms.reset(defaultFieldValues);

      fetchSales({ page: 1, limit: 10 });
    } catch (error) {
      toast({
        title: error,
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  });

  const openEdit = (val) => {
    forms.setValue("id_sales", val.id_sales);
    forms.setValue("sales_name", val.sales_name);
    forms.setValue("id_sales_role", val.sales_role.id_sales_role.toString());
    forms.setValue("id_region", val.id_region);
    forms.setValue("id_city", val.id_city);
    forms.setValue("id_user_pic", val.id_user_pic);
    forms.setValue("email", val.email);
    setFormType("edit");
    setIsOpenForm(true);
  };

  const openDelete = (val) => {
    setDeleteData(val);
    setIsOpenConfirmation(true);
  };

  const handleDelete = async () => {
    try {
      const payload = { id_sales: deleteData.id_sales };

      await deleteSales(payload);

      setIsOpenConfirmation(false);
      setDeleteData({});

      fetchSales({ page: 1, limit: 10 });
    } catch (error) {
      toast({
        title: error,
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    fetchLocations();
    fetchRoles();
    fetchSales({ page: 1, limit: 10 });
  }, []);

  const exportWithQuery = useCallback(() => {
    return getUserSalesExport({
      keyword: query.search,
      id_region: query.id_region,
    });
  }, [query.id_region, query.search]);

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        <Button
          onClickHandler={() => exportWithQuery()}
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        />
        <Button
          label="Tambah Sales"
          variant="DEFAULT"
          size="sm"
          leftIcon={<MdAdd />}
          onClickHandler={() => {
            forms.reset(defaultFieldValues);
            setFormType("create");
            setIsOpenForm(true);
          }}
        />
      </div>
    );
  }, []);

  const hasQuery = Object.values(query).some((p) => p);

  useEffect(() => {
    if (hasQuery) {
      fetchSales({ page: 1, limit: 10, ...query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasQuery, query]);

  const getCityByProvince = () =>
    getCity({ id_region: forms.watch("id_region") });
  const getUserRole = () => getUsers({ id_role: 2 });

  return (
    <Card
      extra={
        "w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col"
      }
    >
      <div className="relative flex items-end gap-3">
        <div className="flex-grow">
          <InputSearch
            rounded
            changeHandler={debounce(
              (e) =>
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  keyword: e.target.value,
                })),
              500
            )}
          />
        </div>
        <div className="w-1/5">
          <InputMultipleSelect
            fetchList={getUsers}
            keyLabel="username"
            noLimit
            label="Pilih user"
            initialLabel="Semua user"
            keyValue="id_user"
            onChange={(value) => {
              setQuery((prevQuery) => ({
                ...prevQuery,
                id_user: value,
              }));
            }}
            value={query.id_user}
            rounded
          />
        </div>
        <div className="w-1/5">
          <InputSelect
            optionList={locationList}
            rounded
            label="Pilih area"
            onChange={(e) =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                id_region: e.target.value,
              }))
            }
          />
        </div>
      </div>

      <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
        <Table
          columnsData={TABLE_COLUMN(openEdit, openDelete)}
          tableData={salesList}
          onTableChange={onTableChange}
          paginationCallback={(page, offset) => {
            if (hasQuery) {
              fetchSales({ page, limit: offset, ...query });
            } else {
              fetchSales({ page, limit: offset });
            }
          }}
          currentPage={table.pagination.current_page}
          perPage={table.pagination.per_page}
          pageCount={table.pagination.total}
        />
      </div>
      <ModalContainer
        isOpen={isOpenForm}
        modalTitle={`${formType === "create" ? "Tambah" : "Ubah"} Karyawan`}
        onClose={() => {
          setIsOpenForm(false);
        }}
        onSubmit={handleSubmit}
      >
        <div className="flex w-[480px] flex-col gap-4">
          <InputField
            register={forms.register("sales_name")}
            label="Nama Sales"
            state={
              getErrorField(forms.formState.errors, "sales_name").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "sales_name")}
          />
          <InputSelect
            optionList={locationList}
            label="Wilayah Penjualan"
            value={forms.watch("id_region")}
            onChange={(e) => forms.setValue("id_region", e.target.value)}
            state={
              getErrorField(forms.formState.errors, "id_region").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "id_region")}
          />
          <InputMultipleSelect
            required
            label="Kota"
            disabled={!forms.watch("id_region")}
            fetchList={getCityByProvince}
            keyLabel="name"
            keyValue="id_city"
            initialLabel={"Pilih Kota"}
            state={
              getErrorField(forms.formState.errors, "id_city").show
                ? "error"
                : ""
            }
            getLabels={(labels) => forms.setValue("sales_labels", labels)}
            errors={getErrorField(forms.formState.errors, "id_city")}
            onChange={(value) => {
              forms.setValue("id_city", value, {
                shouldValidate: true,
              });
            }}
            value={forms.watch("id_city", [])}
          />
          <InputMultipleSelect
            required
            label="PIC"
            fetchList={getUserRole}
            keyLabel="username"
            keyValue="id_user"
            initialLabel={"Pilih PIC"}
            state={
              getErrorField(forms.formState.errors, "id_user_pic").show
                ? "error"
                : ""
            }
            getLabels={(labels) => forms.setValue("sales_labels", labels)}
            errors={getErrorField(forms.formState.errors, "id_user_pic")}
            onChange={(value) => {
              forms.setValue("id_user_pic", value, {
                shouldValidate: true,
              });
            }}
            value={forms.watch("id_user_pic", [])}
          />
          <div className="flex flex-col gap-3">
            <Text variant="linkBold" content="Foto Karyawan" />
            <div className="flex gap-3">
              {[1].map((v, index) => (
                <div key={v} className="aspect-square w-1/6">
                  <InputUpload
                    accept="image/*"
                    setValue={(data) =>
                      forms.setValue(`image_url.${index}`, data[0].url)
                    }
                    image={forms.watch(`image_url.${index}`, "")}
                  />
                </div>
              ))}
            </div>
          </div>
          <InputSelect
            optionList={roleList}
            label="Role"
            value={forms.watch("id_sales_role")}
            onChange={(e) =>
              forms.setValue("id_sales_role", e.target.value.toString())
            }
            state={
              getErrorField(forms.formState.errors, "id_sales_role").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "id_sales_role")}
          />
          {forms.watch("id_sales_role") === "1" && (
            <div>
              <InputField
                variant="auth"
                extra="mb-3"
                label="Email"
                placeholder="Contoh: anandabudiman@gmail.com"
                id="email"
                type="text"
                register={forms.register("email")}
                state={
                  getErrorField(forms.formState.errors, "email").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "email")}
              />

              <InputField
                variant="auth"
                extra="mb-3"
                label="Kata Sandi"
                placeholder="Min. 8 characters"
                id="password"
                type="password"
                register={forms.register("password")}
                state={
                  getErrorField(forms.formState.errors, "password").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "password")}
              />

              <InputField
                variant="auth"
                extra="mb-3"
                label="Konfirmasi Kata Sandi"
                placeholder="Min. 8 characters"
                id="password"
                type="password"
                register={forms.register("confirm_password")}
                state={
                  getErrorField(forms.formState.errors, "confirm_password").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(
                  forms.formState.errors,
                  "confirm_password"
                )}
              />
            </div>
          )}

          {forms.watch("id_sales_role") === "2" && (
            <div>
              <InputField
                variant="auth"
                extra="mb-3"
                label="PIN"
                placeholder="Max. 6 characters"
                id="password"
                type="pin"
                register={forms.register("pin")}
                state={
                  getErrorField(forms.formState.errors, "pin").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "pin")}
              />

              <InputField
                variant="auth"
                extra="mb-3"
                label="Konfirmasi PIN"
                placeholder="Max. 6 characters"
                type="pin"
                register={forms.register("confirm_pin")}
                state={
                  getErrorField(forms.formState.errors, "confirm_pin").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "confirm_pin")}
              />
            </div>
          )}
        </div>
      </ModalContainer>
      <Confirmation
        isOpen={isOpenConfirmation}
        modalTitle={`Konfirmasi Hapus`}
        onClose={() => {
          setIsOpenConfirmation(false);
        }}
        onSubmit={handleDelete}
      >
        <div className="flex max-h-96 w-[480px] flex-col gap-4 overflow-y-auto pr-2">
          <div>
            Menghapus sales <b>{deleteData.sales_name}</b> akan menghilangkan
            data sales secara permanen dari daftar.
          </div>
          <div>
            Sales dari Toko/Pelanggan yang dimiliki oleh{" "}
            <b>{deleteData.sales_name}</b> akan dikosongkan sementara .
            Lanjutkan?
          </div>
        </div>
      </Confirmation>
    </Card>
  );
};

export default Sales;
