import { STATUS_ENUM } from "../config/enum";

const StatusColumn = ({ status, original }) => {
  let value = { label: "", style: "" };

  if (original?.stock_type === 1) {
    switch (status) {
      case STATUS_ENUM.DRAFT:
        value = { label: "Draf", style: "bg-[#E5E5E5] text-[#0A1A1B]" };
        break;

      case STATUS_ENUM.PROCESS:
        value = { label: "Diproses", style: "bg-[#FEF2C7] text-[#E99518]" };
        break;

      case STATUS_ENUM.SENT:
        value = { label: "Dikirim", style: "bg-[#D8EAFF] text-[#3965FF]" };
        break;

      case STATUS_ENUM.RECEIVED:
        value = { label: "Selesai", style: "bg-[#EBFAF5] text-[#04C99E]" };
        break;
      case STATUS_ENUM.CANCEL:
        value = { label: "Batal", style: "bg-[#E5E5E5] text-[#0A1A1B]" };
        break;

      default:
        break;
    }
  } else if (original?.stock_type === 2) {
    switch (status) {
      case STATUS_ENUM.DRAFT:
        value = { label: "Draf", style: "bg-[#E5E5E5] text-[#0A1A1B]" };
        break;

      case STATUS_ENUM.PROCESS:
        value = { label: "Selesai", style: "bg-[#EBFAF5] text-[#04C99E]" };
        break;

      default:
        break;
    }
  }

  return (
    <div
      className={`rounded-full px-2 py-1 tracking-wide ${value.style} w-fit text-xs`}
    >
      {value.label}
    </div>
  );
};

export default StatusColumn;
