import { useContext, useEffect, useState } from "react";
import Table from "components/Table";
import Card from "components/card";
import { TABLE_COLUMN } from "./enum";
import { LayoutContext } from "context/LayoutContext";
import InputSearch from "components/Form/InputSearch";
import { useToast } from "@chakra-ui/react";
import { getStockList } from "apis/stock_list";
import { debounce } from "helper/index";
import { getGudang } from "apis/gudang";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getUsers } from "apis/users";

const StockList = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const toast = useToast();
  const [stockListData, setStockListData] = useState([]);
  const [table, setTable] = useState({
    loading: false,
    pagination: { total: 1, current_page: 1, per_page: 10 },
    watch: {
      keyword: "",
      id_gudang: "",
      id_user: "",
    },
  });

  const fetchStock = async (params = {}) => {
    try {
      const res = await getStockList(params);
      setStockListData(res.data);
      setTable((prev) => ({
        ...prev,
        pagination: {
          total: res.last_page,
          per_page: res.per_page,
          current_page: res.current_page,
        },
      }));
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data pelanggan",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    fetchStock({ page: 1, limit: 10 });
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        {/* <Button label="Export Excel" variant="GHOST" size="sm" leftIcon={<MdOutlineFileUpload />} /> */}
      </div>
    );
  }, []);

  useEffect(() => {
    fetchStock({
      page: 1,
      limit: 10,
      ...(table.watch.keyword && { keyword: table.watch.keyword }),
      ...(table.watch.id_gudang && { id_gudang: table.watch.id_gudang }),
      ...(table.watch.id_user && { id_user: table.watch.id_user }),
    });
  }, [table.watch]);

  return (
    <Card
      extra={
        "w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col"
      }
    >
      <div className="relative flex items-end gap-3">
        <div className="flex-grow">
          <InputSearch
            changeHandler={debounce((e) => {
              setTable((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, keyword: e.target.value },
              }));
            }, 500)}
            rounded
          />
        </div>
        <div className="w-1/5">
          <InputMultipleSelect
            fetchList={getUsers}
            keyLabel="username"
            noLimit
            label="Pilih user"
            initialLabel="Semua user"
            keyValue="id_user"
            onChange={(value) => {
              setTable((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, id_user: value },
              }));
            }}
            value={table.watch.id_user}
            rounded
          />
        </div>
        <div className="w-1/5">
          <InputMultipleSelect
            fetchList={getGudang}
            keyLabel="gudang_name"
            noLimit
            label="Pilih gudang"
            initialLabel="Semua gudang"
            keyValue="id_gudang"
            onChange={(value) => {
              setTable((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, id_gudang: value },
              }));
            }}
            value={table.watch.id_gudang}
            rounded
          />
        </div>
      </div>

      <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
        <Table
          columnsData={TABLE_COLUMN}
          tableData={stockListData}
          paginationCallback={(page, offset) =>
            fetchStock({
              page,
              limit: offset,
              ...(table.watch.keyword && { keyword: table.watch.keyword }),
              ...(table.watch.id_gudang && { id_gudang: table.watch.id_gudang }),
              ...(table.watch.id_user && { id_user: table.watch.id_user }),
            })
          }
          currentPage={table.pagination.current_page}
          perPage={table.pagination.per_page}
          pageCount={table.pagination.total}
        />
      </div>
    </Card>
  );
};

export default StockList;
